import React, { Component } from 'react'; 
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import { Overlay } from './global';
import CustomizedSnackbars from './material-snackbars';
import LivenessFaceClass from './liveclass';
import './css/google.css';
import './css/styles.css';
import Logo from './logo';


let currentTab = 0
let canvas = null;
let globalfiles = null;

class Home extends Component {

	constructor(props) {
		super(props);

		//alert('constructor');
		
		this.state = {
			createLivenessApiData : "",
			showliveness: true,
			referenceimage: "",
			urlreferenceimage: "",
			loading: false,
			openNotification: false,
			notificationVariant: 'error',
			notificationMessage: '',
			resultList: [],

		};


		this.closeNotification = this.closeNotification.bind(this);
		this.LoadUI = this.LoadUI.bind(this);
		
		this.showTab = this.showTab.bind(this);
		this.nextPrev = this.nextPrev.bind(this);
		this.startInit = this.startInit.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.fixStepIndicator = this.fixStepIndicator.bind(this);
		this.handleVideo = this.handleVideo.bind(this);
		this._cleanImage = this._cleanImage.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		this._getImage = this._getImage.bind(this);
		
		this._capture = this._capture.bind(this);
		this.videoError = this.videoError.bind(this);
		
		this.errorCallback = this.errorCallback.bind(this);
		
		this.previewImage = this.previewImage.bind(this);
		this.getFaceLivenessSessionResults = this.getFaceLivenessSessionResults.bind(this);
		this.startVideoSteps = this.startVideoSteps.bind(this);
		this.showSnackbar = this.showSnackbar.bind(this);
		this.changeButtonLabel= this.changeButtonLabel.bind(this);
		
	}

	componentWillReceiveProps(nextProps){
		//alert('componentWillReceiveProps');
	}    

	componentDidMount() {
		//alert('didmount');
		this.LoadUI();
	}
	showSnackbar(type, message){
		this.setState({loading: false, openNotification: true, notificationVariant: type,notificationMessage:message});

	}
	changeButtonLabel(){
		/*
		let btncheck = document.getElementsByClassName("amplify-button amplify-field-group__control amplify-button--primary");
		if(btncheck != null){
			
			for(var i=0; i<btncheck.length;i++){
				if(btncheck[i] != null){
					btncheck[i].innerText="Iniciar verificação";
				}
			}
		}
		*/

	}
	LoadUI() {
		//alert('didmount');

		this.changeButtonLabel();
		
	}
	async getFaceLivenessSessionResults(data){
		//alert(JSON.stringify(data));
		await this.setState({referenceimage:data.tag ,urlreferenceimage:data.tag2 ,showliveness:false, loading: false, openNotification: true, notificationVariant: 'success',notificationMessage: 'Voce é uma pessoal real.'});
		//alert('INICIAR STEPS');
		this.startVideoSteps(data.tag2, data.tag);
	}
	updatePreviewImage(files){
		//console.log(files);
		if(files != null ){
			globalfiles = files;
				var file = files[0];
				// This code is only for demo ...
				
				//console.log("name : " + file.name);
				//console.log("size : " + file.size);
				//console.log("type : " + file.type);
				//console.log("date : " + file.lastModified);
				
				this.previewImage(file);
		}
	}
    startVideoSteps(url,base64){
		//alert(url);
		//alert(base64);
		let secprincipal=document.getElementById('secprincipal');
			if(secprincipal != null){
				secprincipal.style.display='';
			}
			let tabs = document.getElementsByClassName("tab")
			this.showTab(currentTab >= tabs.length ? (tabs.length - 1) : currentTab)

			
			let webcamImagePreview= document.getElementById('webcamImagePreview');
			if (webcamImagePreview != null){
				webcamImagePreview.innerHTML = '';
			  }
			  
			let preview = document.getElementById('imagePreview');
			
			if (preview != null){
			  preview.innerHTML = '';
			}
			
			let review = document.getElementById('imageReview');
	
			if (review != null){
			  review.innerHTML = '';
			}
			/*
			var canvas = document.createElement('canvas');
			canvas.width = 640;
			canvas.height = 480;
		
			var ctx = canvas.getContext('2d');
			ctx.scale(1, 1);
			const image = new Image();
			image.src = url;
			image.onload = () => {
			  ctx.drawImage(image, 0, 0, 640, 480);
			};
			*/
	
			const splitedBase64 = base64.split("base64,"); //canvas.toDataURL('image/png').split("base64,");
		
			if (splitedBase64 && splitedBase64.length > 1) {
				//alert('display');
				var showimag=document.getElementById('webcamImagePreview');
				//showimag.innerHTML='<div class="image-preview-content"><button type="button" onClick="_cleanImage()" >X</button><img style="width: 100%; height: 100%;" src='+canvas.toDataURL('image/png')+' /></div>';
				var stringimg='<img style="width: auto; height: 100%;" src='+base64+' />';

				showimag.innerHTML='<div class="image-preview-content">'+stringimg+'</div>';

				if (preview != null)
					preview.innerHTML = stringimg;

				if (review != null)
					review.innerHTML = stringimg;
			   
					canvas=base64;
			}


			let _self = this;			
			document.getElementById('FilesUpload').addEventListener('change', function() {
				_self.updatePreviewImage(this.files)
			}, false);

			//document.getElementById('FilesUpload').addEventListener('change', (e, files) => this.updatePreviewImage(e, files), false);

			

            /*		
			navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia |navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
		
			if (navigator.getUserMedia) {
				var hdConstraints = {
					video: {
						mandatory: {
							minWidth: 1280,
							minHeight: 720
						}
					}
				};				
		
				// Not showing vendor prefixes.
				navigator.getUserMedia({video: true, audio: true}, function(localMediaStream) {
					var video = document.getElementById("largeVideo");
					video.srcObject = localMediaStream;
				
					// Note: onloadedmetadata doesn't fire in Chrome when using it with getUserMedia.
					// See crbug.com/110938.
					video.onloadedmetadata = function(e) {
						// Ready to go. Do some stuff.
					};
				}, this.errorCallback);

			}
			*/
		
	}

	
	showTab(n) {
	  //console.log("===  showTab")
	  //console.log(n)
	  //console.log("showTab ===")
	  var x = document.getElementsByClassName("tab")
	  x[n].style.display = "block"
	  document.getElementById("initBtn").style.display = "none";
	
	  if (n == 0) {
		document.getElementById("prevBtn").style.display = "none";
	  } else {
		document.getElementById("prevBtn").style.display = "inline";
	  }
	  if (n == (x.length - 1)) {
		document.getElementById("nextBtn").innerHTML = "Processar";
		document.getElementById("initBtn").style.display = "";
	  } else {
		document.getElementById("nextBtn").innerHTML = "Próximo";
	  }
	
	  this.fixStepIndicator(n)
	}
	startInit(){
		window.document.location.href="/";
	}
	nextPrev(n) {
	  //console.log("=== nextPrev")
	  //console.log(n)
	  //console.log("nextPrev ===")
	  var x = document.getElementsByClassName("tab");
	
	  if (n == 1 && !this.validateForm()) return false;
	
	  if (x[currentTab] != null && ((n == 1 && currentTab < (x.length - 1)) || n == -1 ))
		x[currentTab].style.display = "none";
	  currentTab = currentTab + n;
	
	  if (currentTab < (x.length - 1)) {
		document.getElementById("proccessSuccess").style.display='none';
		document.getElementById("proccessError").style.display='none';
		document.getElementById("response").style.display='none';
	  }
	
	  if (currentTab >= x.length) {
		currentTab = currentTab - 1;
		this.uploadFile(this.document)
		return false;
	  }
	  this.showTab(currentTab >= x.length ? (x.length - 1) : currentTab);
	}
	
	validateForm() {
	  var x, y, i, valid = true;
	  x = document.getElementsByClassName("tab");
	
	  if (currentTab == 0) {
		y = document.getElementById("webcamImagePreview");
		if(y.innerHTML == '') {
		  this.showSnackbar("error", "Você precisa capturar uma foto!")
		  valid = false;
		}
	  } else if (currentTab == 1) {
		y = document.getElementById("gallery")
		if(y.innerHTML == "") {
			this.showSnackbar("error", "Você precisa inserir a foto de um documento!")
		  valid = false;
		}
	  }
	
	  if (valid) {
		document.getElementsByClassName("step")[currentTab].className += " finish";
	  }
	  return valid;
	}
	
	fixStepIndicator(n) {
	  var i, x = document.getElementsByClassName("step");
	  for (i = 0; i < x.length; i++) {
		x[i].className = x[i].className.replace(" active", "");
	  }
	  x[n].className += " active";
	}
	
	handleVideo(stream) {
		var video = document.getElementById("largeVideo");
	  video.src = window.URL.createObjectURL(stream);
	  video.play();
	}
	
	_cleanImage() {
	  var showimag=document.getElementById('webcamImagePreview');
	  showimag.innerHTML='';
	  
	  let preview = document.getElementById('imagePreview')
	  if (preview != null)
		preview.innerHTML = ''
	  let review = document.getElementById('imageReview')
	  if (review != null)
		review.innerHTML = ''
	}
	
	uploadFile(file){
	  if(file == null){
			file=document.getElementById("FilesUpload");
	  }
			
	  if (file.value == "" || canvas == null) {
		alert("Selecione um documento para comparação.");
		return false;
	  }			
	
	  var webcamImagePreview= document.getElementById('webcamImagePreview');
	  if(webcamImagePreview.innerHTML == ''){
		alert('Você deve capturar uma imagem antes de enviar.');
	  }
	
	  document.getElementById("proccessSuccess").style.display='none';
	  document.getElementById("proccessError").style.display='none';
	  document.getElementById("response").style.display='none';
				
		var url = 'https://speedmais.api.homologa.2do.mexx.ai/api/users/comparetwofaces';
		var xhr = new XMLHttpRequest();
		var fd = new FormData();
		xhr.open("POST", url, true);
		xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
		xhr.onreadystatechange = () => { //function() {
		 // alert(xhr.readyState);
		if (xhr.readyState == 4 && xhr.status == 200) {
		  // Every thing ok, file uploaded
		  //console.log(xhr.responseText); // handle response.
		  var json = JSON.parse(xhr.responseText);
		  if(json.success) {
			 if(json.data && json.data.facescount > 0){
						//document.getElementById("result").innerHTML=xhr.responseText;
						let responseText = '<p>Faces encontradas mas nenhuma foi confirmada.</p>'
						if(json.data.allfacesmatch) {
							this.showSnackbar('success', 'Reconhecimento facial confirmado!');
							document.getElementById("proccessSuccess").style.display='inline';
							document.getElementById("proccessError").style.display='none';
							document.getElementById("response").style.display='inline';
							if (json.data.facesfound && json.data.facesfound.length > 0) {
								responseText = ''
								let facesFound = json.data.facesfound[0]
								if (facesFound && facesFound.similarity)
								responseText += `<p><strong>Similaridade</strong>: ${facesFound.similarity}%</p><br>`
								if (facesFound && facesFound.confidence) {
								responseText += `<p><strong>Confiança</strong>: ${facesFound.confidence}%</p><br>`
								}
								responseText +=facesFound.message
							}
							this.setState({ loading:false });

							document.getElementById("response").innerHTML='<p>Reconhecimento facial confirmado!</p><Br><Br>'+responseText
					} else {
							if (json.data.facesfound && json.data.facesfound.length > 0) {
								let facesFound = json.data.facesfound[0]
								if (facesFound && facesFound.confidence) {
								responseText += `<br><p><strong>Confiança</strong>: ${facesFound.confidence}%</p><br>`+facesFound.message
								}
							}		
							this.showSnackbar('info', 'Faces encontradas mas nenhuma foi confirmada.');
							document.getElementById("proccessError").style.display='inline';
							document.getElementById("proccessSuccess").style.display='none';
							document.getElementById("response").style.display='inline';
							document.getElementById("response").innerHTML=responseText
							this.setState({ loading:false });

						}
			   }
			   else{
				this.showSnackbar('error', 'Nenhuma face foi encontrada na imagem.');
					document.getElementById("proccessError").style.display='inline';
					document.getElementById("proccessSuccess").style.display='none';
					document.getElementById("response").style.display='inline';
					document.getElementById("response").innerHTML="Nenhuma face foi encontrada na imagem."
					this.setState({ loading:false });
				}	
		  } else{
			
				if(json.errors.length > 0){
					this.showSnackbar('error', 'Houve algum erro ao realizar o reconhecimento facial.\n\n'+json.errors[0]);
					document.getElementById("proccessError").style.display='inline';
					document.getElementById("proccessSuccess").style.display='none';
					document.getElementById("response").style.display='inline';
					document.getElementById("response").innerHTML='<p>Houve algum erro ao realizar o reconhecimento facial.</p>'
				}
		  }
		  
		} else{

			if (xhr.readyState === 4 ) {
			
				this.setState({ loading:false });
			}
			if (xhr.readyState === 4 && xhr.status === 400) {
				if(xhr.responseText !== ''){
					//alert(xhr.responseText)
					console.log(xhr.responseText); // handle response.
					var jsonresult = JSON.parse(xhr.responseText);
					if(!jsonresult.success) {
						if(jsonresult.errors.length > 0){
							this.showSnackbar('error', jsonresult.errors[0]);
							document.getElementById("proccessError").style.display='inline';
							document.getElementById("proccessSuccess").style.display='none';
							document.getElementById("response").style.display='inline';
							document.getElementById("response").innerHTML='<p>'+jsonresult.errors[0]+'</p>';
						}
					}
				}

			}
			else{
				this.showSnackbar('error', 'Houve algum erro ao realizar o reconhecimento facial.');
				document.getElementById("proccessError").style.display='inline';
				document.getElementById("proccessSuccess").style.display='none';
				document.getElementById("response").style.display='inline';
				document.getElementById("response").innerHTML='<p>Houve algum erro ao realizar o reconhecimento facial.</p>'

			}

		}
	  };
	
	  for(var i = 0; i<globalfiles.length; i++){
		fd.append("FilesUpload", globalfiles[i]);
	  }
		
	  //fd.append("Base64", canvas.toDataURL('image/png'));
	  fd.append("Base64", canvas);
		this.setState({ loading:true });

		xhr.send(fd);
	}
	
	_getImage() {
		canvas = this._capture(document.getElementById('largeVideo'), 1);
		const splitedBase64 = canvas.toDataURL('image/png').split("base64,");
	
		if (splitedBase64 && splitedBase64.length > 1) {
			var formattedBase64= splitedBase64[1];
			var showimag=document.getElementById('webcamImagePreview');
			showimag.innerHTML='<div class="image-preview-content"><button type="button" onClick="_cleanImage()" >X</button><img style="width: 100%; height: 100%;" src='+canvas.toDataURL('image/png')+' /></div>';
		let preview = document.getElementById('imagePreview')
		if (preview != null)
		  preview.innerHTML = '<img style="width: auto; height: 100%;" src='+canvas.toDataURL('image/png')+' />';
		let review = document.getElementById('imageReview')
		if (review != null)
		  review.innerHTML = '<img style="width: auto; height: 100%;" src='+canvas.toDataURL('image/png')+' />';
		}
	}
	
	_capture(video, scaleFactor) {
		if (scaleFactor == null) {
			scaleFactor = 0.5;
		}
		var w = video.videoWidth * scaleFactor;
		var h = video.videoHeight * scaleFactor;
		var canvas = document.createElement('canvas');
		canvas.width = w;
		canvas.height = h;
	
		var ctx = canvas.getContext('2d');
		ctx.scale(1, 1);
		ctx.drawImage(video, 0, 0, w, h);
		return canvas;
	}
	
	videoError(e) {
		alert('error');
	}
	
	errorCallback = function(e) {
	  //console.log('Reeeejected!', e);
		alert('error');
	}
	
	previewImage(fileData) {
		var galleryId = "gallery";
		
		var gallery = document.getElementById(galleryId);
		var imageType = /image.*/;
		
	  let file = fileData
	
	  //console.log("file")
	  //console.log(file)
	
		if (!file.type.match(imageType)) {
			throw "File Type must be an image";
		}
		
		var thumb = document.createElement("div");
		thumb.classList.add('thumbnail'); // Add the class thumbnail to the created div
		thumb.style.height = '100%'
	
		var img = document.createElement("img");
		img.file = file;
	  img.style.height = '100%'
	  img.style.width = 'auto'
	
		thumb.appendChild(img);
		gallery.appendChild(thumb);
	
	  var galleryPreview = document.getElementById("galleryPreview")
	  if (galleryPreview != null) {
		galleryPreview.innerHTML = ''
		//console.log("galleryPreview")
		//console.log(galleryPreview)
	
		let previewThumb = document.createElement("div");
		previewThumb.classList.add('thumbnail'); // Add the class thumbnail to the created div
		previewThumb.style.height = '100%'
	
		let previewImg = document.createElement("img");
		previewImg.file = file;
		previewImg.style.height = '100%'
		previewImg.style.width = 'auto'
	
		previewThumb.appendChild(previewImg);
		galleryPreview.appendChild(previewThumb);
	
		var reader = new FileReader();
		reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(previewImg);
		reader.readAsDataURL(file);
	  }
	
	  var galleryReview = document.getElementById("galleryReview")
	  if (galleryReview != null) {
		galleryReview.innerHTML = ''
		//console.log("galleryReview")
		//console.log(galleryReview)
	
		let reviewThumb = document.createElement("div");
		reviewThumb.classList.add('thumbnail'); // Add the class thumbnail to the created div
		reviewThumb.style.height = '100%'
	
		let reviewImg = document.createElement("img");
		reviewImg.file = file;
		reviewImg.style.height = '100%'
		reviewImg.style.width = 'auto'
	
		reviewThumb.appendChild(reviewImg);
		galleryReview.appendChild(reviewThumb);
	
		var reader = new FileReader();
		reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(reviewImg);
		reader.readAsDataURL(file);
	  }
		
		// Using FileReader to display the image content
		var reader = new FileReader();
		reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(img);
		reader.readAsDataURL(file);
	}


	closeNotification() {
		this.setState({ openNotification: false });
	}
	render() {
		return (
			<div>
						{this.state.loading && (
													<Overlay>
														<Loader />
													</Overlay>
												)}
				

					<div className="container">
						<header className="header">
							<div className="logo">
								<Logo width="170" />
							</div>
							<div className="title">
								<h1>Mexx 2Face Demonstração</h1>
							</div>
						</header>
						{this.state.showliveness && (
						<article className="liveness-face" >
								<ThemeProvider>
									<LivenessFaceClass getFaceLivenessSessionResults={this.getFaceLivenessSessionResults} 
									showSnackbar={this.showSnackbar} changeButtonLabel={this.changeButtonLabel}/>
								</ThemeProvider>
						</article>	
					)}						
						{/*!this.state.showliveness || (	)*/}
						<section id="secprincipal" className="principal" style={{display: 'none'}}>
							<form id="compareImagesForm" encType="multipart/form-data" action="">
								<input type="file" id="FilesUpload" accept="image/*" hidden/>
								<div id="webcamImagePreview" style={{display: 'none'}} ></div>
								<div id="gallery" style={{display: 'none'}} ></div>
								<header>
								</header>

												
									<section>
										<article className="tab">
											<header className="tab-header">
												<h6>Imagem capturada do seu rosto</h6>
											</header>
											<section className="tab-section">
												<article className="tab-article-webcam">
													<div id = 'largeVideoContainer'>
														<div id = 'largeVideoElementsContainer'>
															<div id = 'largeVideoWrapper' role = 'figure' >
																{/*<video autoPlay id='largeVideo' playsInline />*/}
																{/*<img style={{width:"auto", height: "100%"}} src={this.state.urlreferenceimage} alt='Imagem capturada' />*/}
															</div>
														</div>
													</div>
												</article>
												{/*
												<article className="tab-article-capture">
													<button type="button" disabled className="success-button" id="captureImage" onClick={() => this._getImage()}>Capturar foto</button>
												</article>
												*/}
												<article className="tab-article-preview" id="imagePreview">
												     {/* <img style={{width:"auto", height: "100%"}} src={this.state.urlreferenceimage} alt='Imagem capturada' /> */}
												</article>
											</section>
										</article>
										<article className="tab">
											<header className="tab-header">
												<h6>Insira uma foto do seu documento, que apareça seu rosto</h6>
											</header>
											<section className="tab-section">
												<article className="tab-article-insert-file">
													<label id="fileUploadButtonLabel" htmlFor="FilesUpload">Selecione seu documento</label>

												</article>
												<article className="tab-article-file-galery" id="galleryPreview"></article>
											</section>
										</article>
										<article className="tab">
											<header className="tab-header">
												<h6>Revisar</h6>
											</header>
											<section className="tab-section tab-section-review">
												<article className="tab-article-review tab-article-review-webcam-image">
													<header >
														<p>Foto capturada</p>
													</header>
													<section id="imageReview"></section>
												</article>
												<article className="tab-article-review tab-article-review-match">
													<div className="icon">
														<span id="proccessSuccess" className="material-symbols-outlined" style={{display: 'none', fontSize:'48px', color:'#43a047'}}>check_circle</span>
														<span id="proccessError" className="material-symbols-outlined" style={{display: 'none', fontSize:'48px', color:'#e53935'}}>cancel</span>
													</div>
													<div className="response" id="response">

													</div>
												</article>
												<article className="tab-article-review tab-article-review-gallery-image">
													<header>
														<p>Foto do documento</p>
													</header>
													<section id="galleryReview"></section>
												</article>
											</section>
										</article>
									</section>
									
									<footer>
										<div className="blank"></div>
										<div className="steps">
											<span className="step"></span>
											<span className="step"></span>
											<span className="step"></span>
										</div>
										<div className="buttons">
											<button type="button" id="prevBtn" style={{marginRight: '5px'}} onClick={() => this.nextPrev(-1)}>Anterior</button>
											<button type="button" name="send" className="success-button" id="nextBtn" onClick={() => this.nextPrev(1)}>Próximo</button>
											<button type="button" name="inicio" style={{marginLeft: '5px'}} id="initBtn" onClick={() => this.startInit()}>Início</button>
										</div>
									</footer>
							</form>
						</section>
						
						<footer className="footer">
							<p>CMTECH - Mexx 2Face - Reconhecimento facial de documentos - V0.1 - 2023 - &copy; Todos os direitos reservados.</p>
						</footer>
						<CustomizedSnackbars
														variant={this.state.notificationVariant}
														message={this.state.notificationMessage}
														isOpen={this.state.openNotification}
														toClose={this.closeNotification}
													/>					
						<div id="result"></div>
				  </div>	
			</div>
		
		);
	}
}
Home.propTypes = {
};
const mapStateToProps = state => ({

});
export default Home;



