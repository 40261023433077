import React from 'react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsexports from './aws-exports';
import { Router } from 'react-router-dom';
import { browserHistory } from './history';


import Home from './home';

Amplify.configure(awsexports);

function App() {
  return (
			<Router history={browserHistory}>
              <Home />            
			</Router>
  );
}

export default App;
