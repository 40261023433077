import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Overlay } from './global';
import CustomizedSnackbars from './material-snackbars';

import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('pt');

//import { getRekognitionClient } from './rekognition'

let  urlapi= 'https://dev.api.2do.mexx.ai:5000/api/users';
urlapi='https://speedmais.api.homologa.2do.mexx.ai/api/users';

const dictionary = {
	// use default strings for english
	en: null,
	ptbr: {
	  instructionsHeaderHeadingText: 'Verificação de vida',
	  instructionsHeaderBodyText:
		'Você passará por um processo de verificação facial para provar que é uma pessoa real.',
	  instructionListStepOneText:
		'Quando uma moldura oval aparecer, preencha ela com seu rosto durante 7 segundos.',
	  instructionListStepTwoText: 'Maximize o brilho da tela.',
	  instructionListStepThreeText:
		'Certifique-se de que seu rosto não esteja coberto por óculos de sol ou máscara.',
	  instructionListStepFourText:
		'Vá para uma área bem iluminada que não esteja exposta à luz solar direta.',
	  photosensitivyWarningHeadingText: 'Aviso de fotossensibilidade',
	  photosensitivyWarningBodyText:
		'Esta verificação mostra luzes coloridas. Tenha cuidado se você for fotossensível.',
	  instructionListHeadingText:
		'Siga as instruções para concluir a verificação:',
	  goodFitCaptionText: 'Bom encaixe',
	  tooFarCaptionText: 'Longe demais',

	  instructionsBeginCheckText: 'Iniciar verificação',
	  photosensitivyWarningInfoText:
		'Uma pequena porcentagem de indivíduos pode sofrer ataques epiléticos quando expostos a luzes coloridas. Tenha cuidado se você ou alguém da sua família tiver uma condição epiléptica.',
	  goodFitAltText:
		"Ilustração do rosto de uma pessoa, encaixando-se perfeitamente dentro de um oval.",
	  tooFarAltText:
		"Ilustração do rosto de uma pessoa dentro de um oval; há uma lacuna entre o perímetro da face e os limites do oval.",
	  cameraMinSpecificationsHeadingText:
		'A câmera não atende às especificações mínimas',
	  cameraMinSpecificationsMessageText:
		'A câmera deve suportar pelo menos 320*240 de resolução e 15 quadros por segundo.',
	  cameraNotFoundHeadingText: 'Câmera não acessível.',
	  cameraNotFoundMessageText:
		'Verifique se a câmera está conectada e se as permissões da câmera estão ativadas nas configurações antes de tentar novamente.',
	  retryCameraPermissionsText: 'Tentar novamente',
	  cancelLivenessCheckText: 'Cancelar verificação de vivacidade',
	  recordingIndicatorText: 'gravando...',
	  hintMoveFaceFrontOfCameraText: 'Mova o rosto na frente da câmera',
	  hintTooManyFacesText: 'Certifique-se de que apenas 1(um) rosto esteja na frente da câmera',
	  hintFaceDetectedText: 'Rosto detectado',
	  hintCanNotIdentifyText: 'Mova o rosto na frente da câmera',
	  hintTooCloseText: 'Volte um pouco',
	  hintTooFarText: 'Aproxime-se',
	  hintHoldFacePositionCountdownText: 'Mantenha a posição do rosto durante a contagem regressiva',
	  hintConnectingText: 'Conectando...',
	  hintVerifyingText: 'Verificando...',
	  hintIlluminationTooBrightText: 'Mover para a área mais escura',
	  hintIlluminationTooDarkText: 'Move to brighter area',
	  hintIlluminationNormalText: 'Mover para uma área mais clara',
	  hintHoldFaceForFreshnessText: 'Segure e aguarde firme',

	  
	},
  };


class LivenessFaceClass extends Component {

	constructor(props) {
		super(props);

		//alert('constructor');
		
		this.state = {
			createLivenessApiData : "",
			loading: false,
			openNotification: false,
			notificationVariant: 'error',
			notificationMessage: '',
			resultList: [],

		};


		this.closeNotification = this.closeNotification.bind(this);
		this.fetchCreateLiveness = this.fetchCreateLiveness.bind(this);
		this.handleAnalysisComplete = this.handleAnalysisComplete.bind(this);
		this.handleUserCancel = this.handleUserCancel.bind(this);
		this.handleError = this.handleError.bind(this);
	}

	componentWillReceiveProps(nextProps){
	}    

	componentDidMount() {
		//alert('didmount');
		this.fetchCreateLiveness();
	}

	fetchCreateLiveness = async () => {
		this.setState({ loading:true });
		try {
			const response = await fetch(
				`${urlapi}/getlivenessid`
			);
			const result = await response.json();
			const mockResponse = { sessionId: result.data.data };
			const data = result.data.data;
	
			this.setState({ createLivenessApiData:data });
			localStorage.setItem('createLivenessApiData',data);


				let token = '7s9f4mmen4tro73hiad4q42cdj';//'265g6hinf50pn7om6tbdsfg68r'; // the token you get from the provider
				let domain ='developer'; // Either the domain of the provider(e.g. accounts.your-openid-provider.com) or the provider name, for now the library only supports 'google', 'facebook', 'amazon', 'developer'
				let expiresIn=new Date(); // the time in ms which describes how long the token could live
				
				
				//'custom-labels-console-us-east-1-b3dc2ca135';  // the user object you defined, e.g. { username, email, phone_number }

				let username = 'faceliveness-homologa-speedmais-me';
				let password=  '#77b7ec75.!'; 
				let email = 'faceliveness-homologa-speedmais-me@mexx.ai';
				//'#77b7ec75.!';
				
				try {
					await Auth.signOut({ global: true }).then(async (p) => {
						await Auth.signIn({
						username,
						password,
						attributes: {
							email,          // optional
						},			  
						autoSignIn: { // optional - enables auto sign in after user is confirmed
							enabled: true,
						}
						}).then((user) => {
							if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
							const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
							Auth.completeNewPassword(
								user, // the Cognito User Object
								password,
							)
								.then((user) => {
								// at this time the user is logged in if no MFA required
								console.log('PASSWORD ALTERADO SUECESSO!');
								//console.log(user);
								})
								.catch((e) => {
								console.log(e);
								});
							} else {
							// other situations
							console.log('other situations');
							}
							console.log('SUCESSO!');
							//console.log(user);
							//this.setState({loading: false, openNotification: true, notificationVariant: 'success', notificationMessage: 'Autenticação realizada com sucesso.'});
							this.props.changeButtonLabel();
						})
						.catch((e) => {
							console.log('error signing up:', e);
							this.setState({loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: 'error cancel session:'+JSON.stringify(e)});
						});
					});
				} catch (error) {
					console.log('error login up:', error);
					//this.setState({loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: 'error cancel session:'+JSON.stringify(error)});
				}
				
	
				this.setState({ loading:false });
				this.props.changeButtonLabel();
	
			} catch (error) {
				//console.log('error get session:', error);
				this.setState({loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: 'error cancel session:'+JSON.stringify(error)});
			}
	
	
    };
	handleAnalysisComplete = async () => {
		this.setState({ loading:true });
		try {
			const response = await fetch(
			`${urlapi}/getlivenessid/${this.state.createLivenessApiData}`
			);
			const result = await response.json();
			//console.log(result.data);
			if (result  && result.data && result.data.data > 90) {
				this.setState({loading: false, openNotification: true, notificationVariant: 'success',notificationMessage: 'Voce é uma pessoal real.'});
		        this.props.getFaceLivenessSessionResults(result.data);
				console.log('User is live');
			} else {
				//alert('Não identificamos uma pessoa viva na verificação. Favor atentar para as observações e tentar novamente.');
  			    console.log('User is not live');
				this.setState({loading: false, openNotification: true, notificationVariant: 'info',notificationMessage: 'Não identificamos uma pessoa viva na verificação. Favor atentar para as observações e tentar novamente.'});
			}
			//this.setState({ createLivenessApiData:"" });
		} catch (error) {
			//console.log('error complete operation:', error);
			this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: JSON.stringify(error)});
		}
    };
	handleUserCancel = async () => {
		
		try {
			this.setState({ loading:true });

			/*
			//alert(this.state.createLivenessApiData);
			const response = await fetch(
				`${urlapi}/restartlivenessid/${this.state.createLivenessApiData}`
				);
			const result = await response.json();
			const mockResponse = { sessionId: result.data.data };
			const data = result.data.data ;
			//alert(JSON.stringify(data));

			this.setState({ createLivenessApiData:data });
			localStorage.setItem('createLivenessApiData',data);

			//alert(this.state.createLivenessApiData);
			*/
			var l = window.location;
			l.reload();
		
		} catch (error) {
			//console.log('error cancel session:', error);
			this.setState({loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: 'error cancel session:'+JSON.stringify(error)});
		}
	
	};
	handleError = async (error) => {
			console.log(error);
			try {

				this.setState({ loading:true });

			
			    const response = await fetch(
					`${urlapi}/cancellivenessid/${this.state.createLivenessApiData}`
					);
				const result = await response.json();
				const mockResponse = { sessionId: result.data.data };
				const data = mockResponse;
				//this.setState({ createLivenessApiData:"" });

				this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: "Ocorreu um erro no processo. Favor atualizar a página e tentar novamente!"});
				var l = window.location;
				l.reload();

			} catch (error) {
				this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: JSON.stringify(error)});
				//console.log('error get session:', error);
			}
	
	};  
	closeNotification() {
		this.setState({ openNotification: false });
	}
	render() {
		return (
			<div style={{paddingLeft:0, marginLeft:0}}>
						
				
						{this.state.loading && (
							<Overlay>
								<Loader />
							</Overlay>
						)}
								
						<ThemeProvider>
							{this.state.loading ? (
								<Overlay>
									<Loader />
								</Overlay>							
							) : (
							<FaceLivenessDetector
								sessionId={this.state.createLivenessApiData}
								region="us-east-1"
								onAnalysisComplete={this.handleAnalysisComplete}
								onUserCancel={this.handleUserCancel}
								onError={this.handleError}
								disableInstructionScreen={false}
								displayText={dictionary['ptbr']}
								/>
							)}
							</ThemeProvider>

							<CustomizedSnackbars
                                            variant={this.state.notificationVariant}
                                            message={this.state.notificationMessage}
                                            isOpen={this.state.openNotification}
                                            toClose={this.closeNotification}
                                        />					
			</div>
		);
	}
}
LivenessFaceClass.propTypes = {
};
const mapStateToProps = state => ({

});
export default LivenessFaceClass;

